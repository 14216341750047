<template>
  <div>
    <v-data-table
      v-if="groups"
      style="cursor: pointer;"
      :headers="headers"
      :items="groups"
      :sort-by="['name']"
      item-key="id"
      class="elevation-1"
      :items-per-page="15"
      :search="search"
      @click:row="openItem"
    >
      <template v-slot:item.link="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              color="blue"
              v-on="on"
              @click.stop="
                openLink(`https://chat.whatsapp.com/${item.link.external_id}`)
              "
              >{{ item.link.external_id }}
              <v-icon class="ml-2" small
                >fas fa-external-link-square-alt</v-icon
              ></v-btn
            >
          </template>
          <span>Abrir link</span>
        </v-tooltip>
      </template>
      <template v-slot:item.total_users="{ item }">
        {{ getTotalUsers(item) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="mx-2"
              color="warning"
              @click.stop="$emit('editGroup', item)"
              icon
              ><v-icon>fas fa-edit</v-icon></v-btn
            >
          </template>
          <span>Editar grupo</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="mx-2"
              color="success"
              @click.stop="shareGroup(item)"
              icon
              ><v-icon>fas fa-share</v-icon></v-btn
            >
          </template>
          <span>Compartilhar link</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="mx-2"
              :disabled="searching"
              color="red"
              small
              @click.stop="confirmDeleteGroup(item)"
              ><v-icon>fas fa-minus</v-icon></v-btn
            >
          </template>
          <span>Remover grupo</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogs.links" max-width="1200" scrollable>
      <v-card v-if="selected">
        <v-card-title class="headline" primary-title>
          {{ selected.name }} | Links ativos: {{ countActiveLinks(selected) }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="success" @click="startNewLink()"
                    ><v-icon>fas fa-plus</v-icon></v-btn
                  >
                </template>
                <span>Novo link</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-2"
                    v-on="on"
                    color="warning"
                    @click="startNewLinkBatch()"
                    ><v-icon>fas fa-folder-plus</v-icon></v-btn
                  >
                </template>
                <span>Adicionar vários links</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="linkHeaders"
                :items="selected.links"
                :items-per-page="15"
                class="elevation-1"
                @click:row="editLink"
              >
                <template v-slot:item.updated_at="{ item }">
                  <span>{{ item.updated_at | moment('D/MM/YYYY HH:mm') }}</span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <div>
                    <span v-if="item.newAmount == null">
                      {{ item.amount }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :disabled="searching"
                            class="ml-2"
                            color="blue"
                            style="display: inline-block;"
                            @click="
                              item.newAmount = item.amount
                              selected.links.push({})
                              selected.links.pop()
                            "
                            small
                            icon
                            ><v-icon>fas fa-edit</v-icon></v-btn
                          >
                        </template>
                        <span>Editar quantidade</span>
                      </v-tooltip>
                    </span>
                    <span v-else>
                      <v-text-field
                        label="Qtd"
                        v-model="item.newAmount"
                        style="width: 50px; display: inline-block;"
                        type="number"
                      ></v-text-field>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :disabled="searching"
                            class="ml-2"
                            color="blue"
                            style="display: inline-block;"
                            small
                            @click="editLinkAmount(item)"
                            icon
                            ><v-icon>fas fa-save</v-icon></v-btn
                          >
                        </template>
                        <span>Salvar</span>
                      </v-tooltip>
                    </span>
                  </div>
                </template>
                <template v-slot:item.capacity="{ item }">
                  <div>
                    <span v-if="item.newCapacity == null">
                      {{ item.capacity }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :disabled="searching"
                            class="ml-2"
                            color="blue"
                            style="display: inline-block;"
                            @click="
                              item.newCapacity = item.capacity
                              selected.links.push({})
                              selected.links.pop()
                            "
                            small
                            icon
                            ><v-icon>fas fa-edit</v-icon></v-btn
                          >
                        </template>
                        <span>Editar quantidade</span>
                      </v-tooltip>
                    </span>
                    <span v-else>
                      <v-text-field
                        label="Capacity"
                        v-model="item.newCapacity"
                        style="width: 50px; display: inline-block;"
                        type="number"
                      ></v-text-field>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :disabled="searching"
                            class="ml-2"
                            color="blue"
                            style="display: inline-block;"
                            small
                            @click="editLinkCapacity(item)"
                            icon
                            ><v-icon>fas fa-save</v-icon></v-btn
                          >
                        </template>
                        <span>Salvar</span>
                      </v-tooltip>
                    </span>
                  </div>
                </template>
                <template v-slot:item.order="{ item }">
                  <div>
                    <span v-if="item.newOrder == null">
                      {{ item.order }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :disabled="searching"
                            class="ml-2"
                            color="blue"
                            style="display: inline-block;"
                            @click="
                              item.newOrder = item.order
                              selected.links.push({})
                              selected.links.pop()
                            "
                            small
                            icon
                            ><v-icon>fas fa-edit</v-icon></v-btn
                          >
                        </template>
                        <span>Editar ordem da fila</span>
                      </v-tooltip>
                    </span>
                    <span v-else>
                      <v-text-field
                        label="Ordem (fila)"
                        v-model="item.newOrder"
                        style="width: 50px; display: inline-block;"
                        type="number"
                      ></v-text-field>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :disabled="searching"
                            class="ml-2"
                            color="blue"
                            style="display: inline-block;"
                            small
                            @click="editLinkOrder(item)"
                            icon
                            ><v-icon>fas fa-save</v-icon></v-btn
                          >
                        </template>
                        <span>Salvar</span>
                      </v-tooltip>
                    </span>
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        :disabled="searching"
                        color="red"
                        small
                        @click.stop="confirmDeleteLink(item)"
                        ><v-icon>fas fa-minus</v-icon></v-btn
                      >
                    </template>
                    <span>Remover link</span>
                  </v-tooltip>

                  <v-tooltip top v-if="item.active">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        :disabled="searching"
                        class="ml-2"
                        color="green"
                        small
                        @click.stop="toggleLinkActive(item, false)"
                        icon
                        ><v-icon>fas fa-toggle-on</v-icon></v-btn
                      >
                    </template>
                    <span>Desativar link</span>
                  </v-tooltip>
                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        :disabled="searching"
                        class="ml-2"
                        color="orange"
                        small
                        @click.stop="toggleLinkActive(item, true)"
                        icon
                        ><v-icon>fas fa-toggle-off</v-icon></v-btn
                      >
                    </template>
                    <span>Ativar link</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.active="{ item }">
                  {{ item.active ? 'ATIVO' : 'INATIVO' }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.newLink" max-width="500">
      <v-card>
        <v-card-title class="headline">Novo Link</v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12">
              <v-text-field
                v-model="newLink.external_id"
                label="ID (ID do grupo, fica no final do link para entrar no grupo, coloque aqui apenas o ID)"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newLink.capacity"
                label="Capacidade (capacidade total do grupo, recomenda-se colocar 250)"
                hide-details="auto"
                required
                max="256"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            :disabled="searching"
            @click="dialogs.newLink = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            :disabled="searching"
            text
            @click="createNewLink()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.newLinkBatch" max-width="500">
      <v-card>
        <v-card-title class="headline">Novos links</v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12">
              <v-textarea
                solo
                v-model="newLink.batchLinks"
                label="Lista de links (UM LINK POR LINHA)"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newLink.capacity"
                label="Capacidade (capacidade total do grupo, recomenda-se colocar 250)"
                hide-details="auto"
                required
                max="256"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            :disabled="searching"
            @click="dialogs.newLinkBatch = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            :disabled="searching"
            text
            @click="createNewLinkBatch()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.editLink" max-width="500">
      <v-card>
        <v-card-title class="headline">Editar Grupo</v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12">
              <v-text-field
                v-model="editLink.external_id"
                label="ID (ID do grupo, fica no final do link para entrar no grupo, coloque aqui apenas o ID)"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editLink.capacity"
                label="Capacidade (capacidade total do grupo, recomenda-se colocar 250)"
                hide-details="auto"
                required
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            :disabled="searching"
            text
            @click="dialogs.editLink = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            :disabled="searching"
            text
            @click="updateLink()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.share" max-width="900">
      <v-card>
        <v-card-title class="headline">Compartilhar grupo</v-card-title>
        <v-card-text v-if="selected && selected.key">
          <v-row>
            <v-col cols="12">
              <h1>ATENÇÃO <v-icon color="red">fas fa-exclamation</v-icon></h1>
              <p>
                Certifique-se de que o <b>LINK DE BACKUP</b> está configurado
                para este grupo! <br />
                Se der alguma coisa errada na hora do redirect, o usuário será
                redirecionado para o link de backup. <br />
                <b>LINK DE BACKUP</b>:
                <v-btn color="success" @click="openLink(selected.bkp_link)" text
                  >{{ selected.bkp_link }}
                  <v-icon class="ml-2"
                    >fas fa-external-link-square-alt</v-icon
                  ></v-btn
                >
              </p>
            </v-col>
            <v-col cols="12">
              <h1>
                Use sua própria página (<b><i>recomendado</i></b
                >)
              </h1>
              <h2 class="mt-2">
                Coloque esse script dentro da sua página do wordpress
              </h2>
              <v-textarea
                class="mt-2"
                id="linkscript"
                readonly
                solo
                label="TESTE"
                :value="generateRedirectScript(selected)"
              ></v-textarea>
              <div class="text-right">
                <v-btn color="primary" @click="copyTestingCode('linkscript')"
                  >Copiar script<v-icon class="ml-2">fas fa-copy</v-icon></v-btn
                >
              </div>
            </v-col>
            <v-col cols="12">
              <h1>Use nosso link</h1>
              <v-textarea
                class="mt-2"
                id="ourlink"
                readonly
                solo
                label="Link de redirect"
                :value="
                  `${getAPIURL()}redirect?key=${selected.key}&bkp=${
                    selected.bkp_link
                  }`
                "
              ></v-textarea>
              <div class="text-right">
                <v-btn color="error" @click="copyTestingCode('ourlink')"
                  >Copiar <v-icon class="ml-2">fas fa-copy</v-icon></v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'TableOfGroups',
  components: {},
  props: {
    groups: Array,
    search: String
  },
  data() {
    return {
      selected: null,
      searching: false,
      newLink: {},
      editLink: {},
      sortByLinks: 'amount',
      sortByDesc: true,
      dialogs: {
        links: false,
        newLink: false,
        newLinkBatch: false,
        editLink: false,
        share: false
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Nome', value: 'name' },
        { text: 'Descrição', value: 'description' },
        { text: 'Link atual', value: 'link' },
        { text: 'Usuários', value: 'total_users' },
        { text: 'Ações', value: 'actions' }
      ],
      linkHeaders: [
        { text: 'ID', value: 'id' },
        { text: 'Nome', value: 'name' },
        { text: 'ID do grupo', value: 'external_id' },
        { text: 'Ativo', value: 'active' },
        { text: 'Capacidade', value: 'capacity' },
        { text: 'Qtd', value: 'amount' },
        { text: 'Ordem da fila', value: 'order' },
        { text: 'Última atualização', value: 'updated_at' },
        { text: 'Ações', value: 'actions' }
      ]
    }
  },
  mounted() {},
  created() {},
  methods: {
    startNewLink() {
      var vm = this
      vm.dialogs.newLink = true
    },
    startNewLinkBatch() {
      var vm = this
      vm.dialogs.newLinkBatch = true
    },
    startEditLink(_i) {
      var vm = this
      vm.editLink = _i
      vm.dialogs.editLink = true
    },
    createNewLink() {
      var vm = this
      vm.searching = true
      vm.newLink.order = vm.selected.links.length
      vm.$API
        .Request('post', `link/new/${vm.selected.id}`, vm.newLink)
        .then(result => {
          if (result && result.data) {
            vm.$snotify.success('Novo link adicionado com sucesso')
            vm.dialogs.newLink = false
            vm.selected.links.push(result.data)
            vm.$emit('reloadGroups')
            vm.newLink = {}
          }
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    createNewLinkBatch() {
      var vm = this
      vm.searching = true
      try {
        let urlRegex = new RegExp(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gim
        )
        let matches = vm.newLink.batchLinks.match(urlRegex)
        vm.newLink.batchLinks = []
        matches.forEach(link => {
          let _arrLink = link.split('/')
          let _id = _arrLink[_arrLink.length - 1]
          vm.newLink.batchLinks.push(_id)
        })
      } catch (_err) {
        vm.$snotify.error(`Oops... ${_err.message}`)
        vm.searching = false
        return
      }
      vm.$API
        .Request('post', `link/new/${vm.selected.id}`, vm.newLink)
        .then(result => {
          if (result && result.data) {
            if (result.batch) {
              vm.$snotify.success(`${result.newLinks} novos links adicionados`)
              vm.selected.links = result.data
            } else {
              vm.$snotify.success('Novo link adicionado com sucesso')
              vm.selected.links.push(result.data)
            }
            vm.dialogs.newLink = false
            vm.$emit('reloadGroups')
            vm.newLink = {}
          }
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    confirmDeleteLink(_link) {
      var vm = this
      if (confirm(`Tem certeza que deseja remover o link ${_link.name} ?`)) {
        vm.deleteLink(_link)
      }
    },
    deleteLink(_link) {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `link/delete/${_link.id}`, _link)
        .then(result => {
          if (result && result.data) {
            vm.selected = result.data
            vm.$snotify.success('Link removido com sucesso')
            vm.$emit('reloadGroups')
          }
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    confirmDeleteGroup(_group) {
      var vm = this
      if (confirm(`Tem certeza que deseja remover o grupo ${_group.name} ?`)) {
        vm.deleteGroup(_group)
      }
    },
    deleteGroup(_gorup) {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `group/delete/${_gorup.id}`, _gorup)
        .then(result => {
          if (result) {
            vm.selected = null
            vm.$snotify.success('Grupo removido com sucesso')
            vm.$emit('reloadGroups')
          }
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    updateLink() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `link/edit/${vm.editLink.id}`, vm.editLink)
        .then(result => {
          if (result && result.data) {
            vm.$snotify.success('Link editado com sucesso')
            vm.dialogs.editLink = false
            vm.dialogs.links = false
            vm.$emit('reloadGroups')
            vm.editLink = {}
          }
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    toggleLinkActive(_link, status) {
      var vm = this
      _link.active = status
      vm.$API
        .Request('post', `link/edit/${_link.id}`, _link)
        .then(result => {
          if (result && result.data) {
            vm.$snotify.success('Link editado com sucesso')
            //vm.$emit('reloadGroups')
          }
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          _link.active = !status
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    editLinkAmount(_link) {
      var vm = this
      let _oldAmount = _link.amount
      _link.amount = Number(_link.newAmount)
      vm.$API
        .Request('post', `link/edit/${_link.id}`, _link)
        .then(result => {
          if (result && result.data) {
            vm.$snotify.success('Quantidade do link editada com sucesso')
            _link.newAmount = null
            vm.selected.links.push({})
            vm.selected.links.pop()
          }
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          _link.amount = Number(_oldAmount)
          vm.selected.links.push({})
          vm.selected.links.pop()
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    editLinkCapacity(_link) {
      var vm = this
      let _oldCapacity = _link.capacity
      _link.capacity = Number(_link.newCapacity)
      vm.$API
        .Request('post', `link/edit/${_link.id}`, _link)
        .then(result => {
          if (result && result.data) {
            vm.$snotify.success('Capacidade editada com sucesso')
            _link.newCapacity = null
            vm.selected.links.push({})
            vm.selected.links.pop()
          }
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          _link.capacity = Number(_oldCapacity)
          vm.selected.links.push({})
          vm.selected.links.pop()
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    editLinkOrder(_link) {
      var vm = this
      let _oldOrder = _link.order
      _link.order = Number(_link.newOrder)
      vm.$API
        .Request('post', `link/edit/${_link.id}`, _link)
        .then(result => {
          if (result && result.data) {
            vm.$snotify.success('Ordem da fila editada com sucesso')
            _link.newOrder = null
            vm.selected.links.push({})
            vm.selected.links.pop()
          }
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          _link.order = Number(_oldOrder)
          vm.selected.links.push({})
          vm.selected.links.pop()
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    getTotalUsers(_group) {
      let _totalUsers = 0
      _group.links.forEach(link => {
        _totalUsers += link.amount
      })
      return _totalUsers
    },
    openItem(_i) {
      var vm = this
      vm.selected = _i
      vm.dialogs.links = true
    },
    openLink(_url) {
      window.open(_url, '_blanc')
    },
    shareGroup(group) {
      var vm = this
      vm.selected = group
      vm.dialogs.share = true
    },
    getAPIURL() {
      return `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_ROOT}/`
    },
    countActiveLinks(_group) {
      let _activeLinks = 0
      _group.links.forEach(_link => {
        if (_link.active) {
          _activeLinks += 1
        }
      })
      return _activeLinks
    },
    copyTestingCode(_elementID) {
      var vm = this
      let testingCodeToCopy = document.querySelector(`#${_elementID}`)
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        if (document.execCommand('copy')) {
          vm.$snotify.success(`Texto copiado para a área de transferência`)
        } else {
          throw new Error(
            'Não foi possível copiar o texto para área de transferência. Copie manualmente'
          )
        }
      } catch (_err) {
        vm.$snotify.error(`Oops... ${_err.message}`)
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    /* eslint-disable */
    generateRedirectScript(group) {
      var vm = this
      let _script = `&lt;script type="text/javascript">
(()=>{const n=new Request("${vm.getAPIURL()}group?key=${group.key}&bkp=${group.bkp_link}&click=1");fetch(n).then(n=>{n.json().then(n=>e(n)).catch(n=>e(n))}).catch(n=>e(n));const e=n=>{n.url?window.location.replace(n.url):window.location.replace("${group.bkp_link}")}})();
&lt;/script>`;
      return _script.replace('&lt;', '<').replace('&lt;', '<')
    }
      /* eslint-enable */
  },
  computed: {}
}
</script>
