<template>
  <div class="home">
    <p v-if="loading" style="width: 100%; text-align: center;">
      <v-icon>fas fa-spinner fa-spin</v-icon> Carregando ...
    </p>
    <div class="pa-2" v-else-if="groups">
      <template>
        <v-card>
          <v-card-title>
            Produtos
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="fas fa-search"
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field>
            <v-btn
              :disabled="searching"
              color="blue"
              class="ml-5"
              @click="fetchSearch()"
              ><v-icon btn>fas fa-sync</v-icon></v-btn
            >
            <v-btn
              :disabled="searching"
              color="blue"
              class="ml-5"
              @click="startNewGroup()"
              ><v-icon btn>fas fa-plus</v-icon></v-btn
            >
          </v-card-title>
          <table-of-groups
            :groups="groups"
            :search="search"
            @editGroup="startEditGroup"
            @reloadGroups="fetchSearch()"
          />
        </v-card>
      </template>
    </div>
    <v-dialog v-model="dialogs.newGroup" max-width="500">
      <v-card>
        <v-card-title class="headline">Novo Grupo</v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12">
              <v-text-field
                v-model="newGroup.name"
                label="Nome (nome do grupo)"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newGroup.description"
                label="Breve descrição"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="newGroup.capacity_warn"
                label="% para notificação (0 para desativar)"
                hide-details="auto"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newGroup.bkp_link"
                label="Link de backup (caso o redirect venha a falhar, o usuário será redirecionado para este link)"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            :disabled="searching"
            @click="dialogs.newGroup = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            :disabled="searching"
            text
            @click="createNewGroup()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.editGroup" max-width="500">
      <v-card>
        <v-card-title class="headline">Editar Grupo</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="editGroup.name"
                label="Nome (nome do grupo)"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editGroup.description"
                label="Breve descrição"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editGroup.capacity_warn"
                label="% para notificação (0 para desativar)"
                hide-details="auto"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editGroup.bkp_link"
                label="Link de backup (caso o redirect venha a falhar, o usuário será redirecionado para este link)"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            :disabled="searching"
            text
            @click="dialogs.editGroup = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            :disabled="searching"
            text
            @click="updateGroup()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableOfGroupsVue from '../components/TableOfGroups.vue'
// @ is an alias to /src
export default {
  name: 'Groups',
  components: {
    'table-of-groups': TableOfGroupsVue
  },
  data() {
    return {
      newGroup: {},
      editGroup: {},
      dialogs: {
        newGroup: false,
        editGroup: false
      },
      search: null,
      groups: null,
      searching: false,
      loading: true
    }
  },
  mounted() {},
  created() {
    this.fetchSearch()
  },
  methods: {
    startNewGroup() {
      var vm = this
      vm.dialogs.newGroup = true
    },
    startEditGroup(_group) {
      var vm = this
      vm.editGroup = _group
      vm.dialogs.editGroup = true
    },
    createNewGroup() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `group/new`, vm.newGroup)
        .then(result => {
          if (result && result.data) {
            vm.$snotify.success('Grupo adicionado com sucesso')
            vm.dialogs.newGroup = false
            vm.groups.push(result.data)
            vm.newGroup = {}
          }
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    updateGroup() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `group/edit/${vm.editGroup.id}`, vm.editGroup)
        .then(result => {
          if (result && result.data) {
            vm.$snotify.success('Grupo editado com sucesso')
            vm.dialogs.editGroup = false
            vm.searching = false
          }
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    fetchSearch() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `group/list`)
        .then(result => {
          if (result && result.data) {
            vm.groups = result.data
          }
          vm.searching = false
          vm.loading = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    }
  },
  computed: {}
}
</script>
